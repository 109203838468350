/**
 * BLOCK
 *
 * @type utilities
 */

/** ----------------------------------------------------------------
 * COMMON
 */

  .hidden { display: none !important; }
  .visibility-hidden { visibility: hidden; }
  .break { clear: both; display: block; }
  .x-cross { cursor: pointer; }
  .relative { position: relative; }
  .overflow-hidden { overflow: hidden; }