/**
 * RESET INPUT
 *
 * @type mixin
 */


/** ----------------------------------------------------------------
 * COMMON
 */

@mixin orx-reset-input {
  border: 0 none;

  &:focus { outline: 0; }

  // Make webkit render the search input like a normal text field
  &[type=search] { -webkit-appearance: textfield; -webkit-box-sizing: content-box; }

  // Turn off the recent search for webkit. It adds about 15px padding on the left
  @at-root {
    ::-webkit-search-decoration { display: none; }
  }
}