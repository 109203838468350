/**
 * FORMULAIRE BLOCK
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.formulaire-block {
  .formulaire-block-action { display: flex; flex-wrap: wrap; }
  .form-control {
    margin-bottom: 10px; width: 100%;

    .label { text-transform: uppercase; font-size: 12px; font-weight: bold; }
    .input-wrap {
      input,
      textarea { font-size: 16px; -webkit-appearance: none; -moz-appearance: none; appearance: none; }
      textarea { border: 1px solid #e5e5e5; border-radius: 4px; width: 100%; height: 200px; padding: 10px; }
    }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .formulaire-block {
    .formulaire-block-action {
      margin: 0 -15px;

      .form-control { width: 50%; padding: 0 15px; }
    }
  }
}