/**
 * SHADOW
 *
 * @type specific
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.shadow-box { box-shadow: 0 2px 12px rgba(black, 0.12); }