/**
 * FOOTER
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.footer {
  background-color: $orx-brand-secondary; color: white; padding-bottom: 50px; position: relative;

  .footer-top {
    background-color: $orx-brand-primary; color: white;

    .container {
      height: auto; padding: 20px 0; display: flex; align-items: center; justify-content: center; flex-direction: column;
      transition: all 0.2s ease-in-out;

      span { margin: 0; }
    }
    .link-style { text-decoration: none; width: 100%; color: white; font-size: 14px; line-height: 42px; font-weight: $orx-font-weight-bold; letter-spacing: 1px; text-transform: uppercase; }
  }
  .footer-middle {
    .footer-content { padding: 0; margin: 25px 0 0; display: flex; justify-content: flex-start; flex-wrap: wrap; }
    .footer-column {
      width: 100%; margin-bottom: 20px;

      a {
        color: white;

        &:hover { text-decoration: underline; }
      }
      .title { font-size: 16px; font-weight: $orx-font-weight-bold; text-transform: uppercase; margin-bottom: 15px; }
      .item-link {
        color: rgba(white, 0.7); font-size: 14px; line-height: 22px; margin-bottom: 10px; position: relative;

        &.big {
          .link-style { font-size: 18px; font-weight: $orx-font-weight-medium; text-decoration: none; }
        }
        .link-style { color: rgba(white, 0.7); text-transform: uppercase; text-decoration: none; }
      }
      .logo-wrap {
        .link-style {
          color: white; display: inline-flex; align-items: center; justify-content: center; text-decoration: none;

          .logo-label { font-size: 18px; font-family: $orx-font-family-secondary; text-transform: uppercase; transition: all 0.2s ease-in-out; }
          strong {
            font-family: $orx-font-family-primary; font-style: italic; text-transform: none;

            span { font-size: 23px; }
          }
        }
        img { width: 53px; transition: all 0.2s ease-in-out; }
      }
    }
  }
  .footer-bottom {
    @include position(absolute, null null 0 0);
    @include orx-size(100%,50px);
    background-color: rgba(white, 0.04);

    .container { height: 50px; display: flex; justify-content: center; align-items: center; }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */
@media #{$orx-mobile-first-md} {
  .footer {
    .footer-middle {
      margin: 25px 0;

      .footer-content { padding: 0 70px; margin: 50px 0; }
      .footer-column {
        width: 50%;

        .title { font-size: 18px; margin-bottom: 30px; }
        .item-link { font-size: 16px; margin-bottom: 20px; }
      }
    }
    .footer-top {
      .container {
        height: 55px; padding: 0; flex-direction: row;

        span {
          margin: 0 40px;

          &:first-child { margin-left: 0; }
          &:last-child { margin-right: 0; }
        }
      }
      .link-style { line-height: inherit; }
    }
  }
}

@media #{$orx-mobile-first-lg} {
  .footer {
    .footer-top {
      .link-style {
        position: relative;

        &:before {
          @include orx-size(0, 2px);
          @include position(absolute, null null -5px 50%);
          background-color: white; opacity: 0; content: "";
          transform: translateX(-50%); transition: all 0.2s ease-in-out;
        }
        &:hover {
          &:before { width: 100%; opacity: 1; }
        }
      }
    }
    .footer-middle {
      .footer-content { justify-content: center; flex-wrap: nowrap; }
      .footer-column {
        width: calc(100% / 3); margin-bottom: 0;

        .item-link {
           .link-style {
             transition: all 0.2s ease-in-out;

             &:hover { color: white; }
           }
         }
        .logo-wrap {
          .link-style {
            .logo-label { font-size: 28px; }
            strong {
              span { font-size: 38px; }
            }
          }
          img { width: 110px; }
        }
      }
    }
  }
}