/**
 * COLUMN FULL
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.column-full {
  position: relative; margin: 30px 0 50px;

  .column-item { background-color: white; border-radius: 5px; box-shadow: 0 2px 6px rgba(black, 0.12); }
  .image-wrapper {
    height: 802px;

    &:before {
      @include position(absolute, 0 null null 0);
      @include orx-size(100%);
      content: "";
      background: -moz-linear-gradient(-45deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%);
      background: -webkit-linear-gradient(-45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 51%,rgba(255,255,255,1) 100%);
      background: linear-gradient(135deg, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 51%,rgba(255,255,255,1) 100%);
    }
    img {
      @include orx-size(100%);
      object-fit: cover; border-radius: 5px;
    }
    .txt {
      @include position(absolute, 15px 0 null null);
      padding: 0 20px; font-size: 16px; line-height: 24px; text-align: center;

      .para-bold {
        font-size: 20px; line-height: 32px; font-weight: bold;

        strong { color: $orx-brand-primary; }
      }
    }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .column-full {
    .image-wrapper { height: 440px; }
  }
}

@media #{$orx-mobile-first-lg} {
  .column-full {
    .image-wrapper {
      height: 390px;

      .txt {
        width: 780px; padding: 0 40px 0 0;

        .para-bold {
          font-size: 20px; line-height: 32px; font-weight: bold;

          strong { color: $orx-brand-primary; }
        }
      }
    }
  }
}