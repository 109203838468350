/**
 * SELECT WRAP
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.select-wrap {

}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .select-wrap { }
}

@media #{$orx-mobile-first-lg} {
  .select-wrap { }
}