/**
 * WRAPPER FULL WHITE
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.wrapper-full-white {
  background-color: white; position: relative; padding: 5px 0 30px;

  &.bottom-front { margin-bottom: -218px; }
}