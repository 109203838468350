/**
 * PARTNERS
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.partners {
  position: relative; margin: 50px 0 0;

  .h2-style { margin: 15px 0; font-weight: bold; letter-spacing: 1px; }
  .partners-content { margin: 0 40px; }
  .partners-item { outline: none; }
}