/**
 * IMAGE
 *
 * @type utilities
 */

/** ----------------------------------------------------------------
 * COMMON
 */

  img.full { width: 100%; }