/**
 * LIST
 *
 * @type specific
 */


/** ----------------------------------------------------------------
 * COMMON
 */

// INLINE
.list-inline {
  @extend .reset-list;
  & > li { display: inline-block; }
}

// BULLET
.list-bullet {
  @extend .reset-list;
  & > li { margin: 2px 0 2px 10px; position: relative; }
  & > li:before { position: absolute; top: 0; left: -10px; content: "•"; }
}

// DEFINITION
.list-def {
  @extend .reset-list;
  & > dt { display: block; }
  & > dd { margin: 0; display: inline-block; }
}