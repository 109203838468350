/**
 * COLUMN FOUR
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.column-four {
  position: relative; margin: 40px 0 60px;

  .h2-style { margin: 15px 0; font-weight: bold; letter-spacing: 1px; }
  .h4-style {
    text-align: center; font-size: 18px; font-weight: bold; text-transform: uppercase; letter-spacing: 1px; margin: 65px 0; position: relative;

    &:after {
      @include orx-size(150px,2px);
      @include position(absolute, null null -18px 50%);
      background-color: $orx-brand-secondary; content: "";
      transform: translateX(-50%);
    }
  }
  .column-four-content {
    position: relative; display: flex; flex-wrap: wrap; margin: 30px -15px 0;

    .column-item {
      width: 100%; background-color: white; text-align: center; border-radius: 6px; box-shadow: 0 2px 6px rgba(black, 0.12); padding: 20px; margin: 0 15px 30px;

      .image-wrapper {
        height: 180px; margin: -20px -20px 0 -20px;

        img {
          @include orx-size(100%);
          object-fit: cover; border-radius: 6px 6px 0 0;
        }
      }
      .h3-style { font-size: 18px; }
      .txt { font-size: 16px; line-height: 24px; text-align: left; }
      &.color-block {
        background-color: #F2D06B;

        .h3-style { font-size: 24px; font-weight: bold; text-align: center; letter-spacing: 6px; text-transform: uppercase; }
        .txt { font-size: 16px; line-height: 24px; }
        &:first-child { background-color: #F2C53D; }
        &:nth-child(3) { background-color: #F2E8B3; }
        &:last-child { background-color: #F2EDD5; }
      }
    }
  }

}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .column-four {
    .column-four-content {
      .column-item { width: 46%; }
    }
  }
}

@media #{$orx-mobile-first-lg} {
  .column-four {
    .column-four-content {
      flex-wrap: inherit; margin: 30px -15px;

      .column-item { width: 25%; }
    }
  }
}
