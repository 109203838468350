/**
 * LAYOUT
 *
 * @type specific
 */


/** ----------------------------------------------------------------
 * COMMON
 */

// BODY
body {
  font-family: $orx-font-family-primary; background-color: $orx-gray-fa; color: white; font-size: 14px;

  &.body-no-scroll { overflow: hidden; }
}

// LINK
a { text-decoration: none; }

// MAIN LAYOUT
.main-layout {
  .container { max-width: 100%; }
  .container-fluid {
    @include position(relative, null null null null);
    background-color: white;
  }
}

.container { max-width: 100%; }
.container-sidebar-right {
  display: flex; flex-direction: column; position: relative;

  .main-wrap { width: 100%; }
  .sidebar-right { width: 100%; }
}

.container-sidebar-left {
  display: flex; flex-direction: column; position: relative;

  .main-wrap { width: 100%; }
  .sidebar-left { width: 100%; }
}

/** ----------------------------------------------------------------
* MEDIA QUERY
*/

@media #{$orx-mobile-first-md} {
  .main-layout {
    .container-fluid-md { max-width: 100%; position: relative; }
  }
  .md-no-break {
    br { display: none; }
  }
  .column-divider {
    display: flex; justify-content: center;
    .main-column { flex-grow: 1; max-width: 440px; }
    .sidebar { width: 230px; }
  }
}

@media #{$orx-mobile-first-lg} {
  .container-sidebar-right {
    flex-direction: row;

    .main-wrap { width: calc(100% - 405px); }
    .sidebar-right { width: 405px; }
  }
  .container-sidebar-left {
    flex-direction: row;

    .main-wrap { width: calc(100% - 330px); }
    .sidebar-left { width: 330px; }
  }
}

@media #{$orx-mobile-first-xl} {
  .main-layout {
    .container-fluid-md { max-width: #{map-get($container-max-widths, "xl")}; position: relative; }
    .container { max-width: #{map-get($container-max-widths, "xl")}; }
  }
  .container { max-width: #{map-get($container-max-widths, "xl")}; }
  .column-divider {
    .main-column { max-width: 800px; }
    .sidebar { width: 400px; }
  }
}
