/**
 * BACK TO TOP
 *
 * @type element
 */

/** ----------------------------------------------------------------
 * COMMON
 */

:root {
  font-size: 20px;
  --red: #da2c4d;
  --yellow: #f8ab37;
  --green: #2ecc71;
  --white: #ffffff;
  --grey-light: #f2f7f9;
  --grey: #ecedf3;
  --black: #080808;
  --black-blue: #1f2029;
  --black-blue-light: #353746;
  --black-blue-light-2: #404255;
  --black-blue-light-3: #4b4d64;
  --black-light: #424455;
}

@-webkit-keyframes border-transform{
  0%,100% { border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; }
  14% { border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; }
  28% { border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; }
  42% { border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; }
  56% { border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; }
  70% { border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; }
  84% { border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; }
}
@keyframes border-transform{
  0%,100% { border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; }
  14% { border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; }
  28% { border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; }
  42% { border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; }
  56% { border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; }
  70% { border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; }
  84% { border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; }
}

.color-gradient-red-yellow {
  background: linear-gradient(160deg, var(--red), var(--yellow));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.color-gradient-red-yellow .uil:before {
  background: linear-gradient(160deg, var(--red), var(--yellow));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.size-22 {
  font-size: 22px;
}

/* #Progress
================================================== */

.progress-wrap {
  position: fixed;
  right: 15px;
  bottom: 50px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset  0 0 0 2px rgba(173,160,99, 0.60);
  background-color: rgba(173,160,99, 0.18);
  z-index: 18;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
@media #{$orx-mobile-first-lg} {
  .progress-wrap { right: 50px; }
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.progress-wrap::after {
  position: absolute;
  font-family: "baran-icons", Arial;
  content: ">";
  text-align: center;
  line-height: 46px;
  font-size: 18px;
  font-weight: normal;
  color: #79714a;
  left: 1px;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 1;
  transform: rotate(-90deg);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap:hover::after {
  color: black;
}
.progress-wrap::before {
  position: absolute;
  content: "";
  text-align: center;
  line-height: 46px;
  font-size: 24px;
  opacity: 0;
  background-image: linear-gradient(298deg, var(--red), var(--yellow));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 2;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap:hover::before {
  color: black;
}
.progress-wrap svg path {
  fill: none;
}
.progress-wrap svg.progress-circle path {
  stroke: black;
  stroke-width: 4;
  box-sizing:border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}