/**
 * MAIN MENU
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.main-menu {
  @include position(absolute, 50% 78px null null);
  color: white; display: flex; align-items: center; justify-content: center;
  transform: translateY(-50%);

  .main-menu-item {
    margin: 0 10px; position: relative; display: flex; align-items: center; justify-content: center; transition: all 0.2s ease-in-out;

    &:before {
      @include position(absolute, 50% -14px null null);
      @include orx-size(1px, 17px);
      background-color: rgba($orx-brand-primary, 0.3); content: "";
      transform: translateY(-50%);
    }
    &:first-child { margin-left: 0; }
    &:last-child {
      margin-right: 0;

      &:before { display: none; }
    }
    .phone-icon { position: relative; top: 2px; }
    .phone-style {
      color: $orx-brand-primary; font-weight: $orx-font-weight-semi-bold; margin-right: -15px;

      span { color: white; font-weight: $orx-font-weight-light; margin: 0 5px; }
    }
    .icon-wrap {
      font-size: 18px; margin-left: 5px;

      .elem-icon { color: $orx-brand-primary; }
    }
  }
  .link-style {
    color: white; font-size: 14px; font-weight: $orx-font-weight-medium; display: inline-flex; align-items: center; justify-content: center; text-decoration: none;

    &.selected { color: $orx-brand-primary; }
    .label { letter-spacing: 1px; }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .main-menu {
    right: 30px;

    .main-menu-item {
      margin: 0 20px;

      &:before { right: -15px; }
    }
  }
}

@media #{$orx-mobile-first-lg} {
  .main-menu {
    .link-style {
      transition: all 0.2s ease-in-out;

      .label {
        position: relative;

        &:before {
          @include orx-size(0, 2px);
          @include position(absolute, null null -10px 50%);
          background-color: $orx-brand-primary; opacity: 0; content: "";
          transform: translateX(-50%); transition: all 0.2s ease-in-out;
        }
      }

      &:hover {
        color: $orx-brand-primary;

        .label {
          &:before { opacity: 1; width: 100%; }
        }
      }
    }
  }
}