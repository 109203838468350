/**
 * TYPE
 *
 * @type element
 */


/** ----------------------------------------------------------------
 * COMMON
 */

  // UTIL
  .ellipsis { @include ellipsis(); }
  .inline { display: inline; }

  //.link { font-size: $font-size-base; cursor: pointer; }
  .link-decoration { color: inherit; text-decoration: underline; cursor: pointer; }
  .link-decoration:hover { text-decoration: none; }
  .unlink,
  .unlink-group > a { color: inherit; text-decoration: none; }

  // FONT SIZE
  small,
  .small { font-size: 80%; }
  .large { font-size: 120%; }