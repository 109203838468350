/**
 * MOBILE MENU
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.mobile-menu {
  @include position(absolute, 50% 20px null null);
  transform: translateY(-50%);

  .nav-icon {
    @include orx-size(22px, 18px);
    position: relative; cursor: pointer;
    transform: rotate(0deg); transition: .5s ease-in-out;

    span {
      @include position(absolute, 0 null null 0);
      @include orx-size(50%, 2px);
      background-color: $orx-brand-primary; opacity: 1; display: block;
      transform: rotate(0deg); transition: .25s ease-in-out;

      &:nth-child(even) { left: 50%; border-radius: 0 9px 9px 0; }
      &:nth-child(odd) { left: 0; border-radius: 9px 0 0 9px; }
      &:nth-child(1),
      &:nth-child(2) { top: 0; }
      &:nth-child(3),
      &:nth-child(4) { top: 8px; }
      &:nth-child(5),
      &:nth-child(6) { top: 16px; }
    }
  }
  &.open {
    .nav-icon {
      span {
        background-color: white;

        &:nth-child(1),
        &:nth-child(6) { transform: rotate(45deg); }
        &:nth-child(2),
        &:nth-child(5) { transform: rotate(-45deg); }
        &:nth-child(1) { top: 4px; left: 2px; }
        &:nth-child(2) { top: 4px; left: calc(50% - 2px); }
        &:nth-child(3) { left: -50%; opacity: 0; }
        &:nth-child(4) { left: 100%; opacity: 0; }
        &:nth-child(5) { top: 11px; left: 2px; }
        &:nth-child(6) { top: 11px; left: calc(50% - 2px); }
      }
    }
  }
}