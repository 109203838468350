/**
 * SLIDER MULTIPLE
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.slider-multiple,
.slider-multiple-2 {
  .slider-multiple-item {
    height: 495px; outline: 0 none;

    img {
      @include orx-size(100%);
      object-fit: cover; outline: 0 none;
    }
    .h4-style {
      @include position(absolute, -5px null null 12px);
      background-color: rgba(black, 0.8); padding: 10px 15px; color: white;
    }
  }
}

.slider-multiple-thumbs,
.slider-multiple-thumbs-2  {
  margin: 20px 0;

  .slider-multiple-thumbs-item {
    height: 180px; margin: 0 10px; outline: 0 none;

    img {
      @include orx-size(100%);
      object-fit: cover; outline: 0 none;
    }
  }
}