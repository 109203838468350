/**
 * MOBILE MENU PANEL
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.mobile-menu-panel {
  @include position(relative, auto null null auto);
  @include orx-size(100%, calc(100% - 50px));
  background-color: white; z-index: -1; opacity: 0; display: block;
  transition: all 0.2s cubic-bezier(0.46, 0.03, 0.52, 0.96);

  .mobile-menu-panel-content { display: none; padding: 20px; }
  .mobile-menu-panel-item {
    .link-style {
      outline: 0 none; font-weight: bold; text-transform: uppercase; padding: 0 0 10px; margin-bottom: 10px; border-bottom: 1px solid #e5e5e5; display: block; text-decoration: none;

      &.selected { color: $orx-brand-primary; }
    }
    &.phone-block-mobile {
      @include position(absolute, null null 15px 0);
      @include orx-size(100%,auto);
      display: flex; align-items: center; justify-content: center; color: black !important;

      .phone-icon { font-size: 22px; color: $orx-brand-primary; margin-right: 15px; }
      .phone-style {
        color: black !important; font-size: 22px;

        span { color: $orx-brand-primary !important; font-weight: bold; margin-right: 6px; }
      }
    }
  }
  .open-menu-mobile & {
    @include position(fixed, 50px null null 0);
    z-index: 20; opacity: 1;

    .mobile-menu-panel-content { display: block; }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */
@media #{$orx-mobile-first-lg} {
  .mobile-menu-panel {
    @include orx-size(100%, calc(100% - 80px));

    .open-menu-mobile & { top: 80px; }
  }
}

@media #{$orx-mobile-first-xl} {
  .mobile-menu-panel { display: none; }
}