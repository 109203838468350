/**
 * TRANSITION
 *
 * @type mixin
 */


/** ----------------------------------------------------------------
 * COMMON
 */

@mixin orx-transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin orx-transition-transform($duration, $func) {
  -webkit-transition: -webkit-transform $duration $func;
  -moz-transition: -moz-transform $duration $func;
  -ms-transition: -ms-transform $duration $func;
  -o-transition: -o-transform $duration $func;
  transition: transform $duration $func;
}

@mixin orx-transition-delay($delay) {
  -webkit-transition-delay: $delay;
  -moz-transition-delay: $delay;
  -ms-transition-delay: $delay;
  -o-transition-delay: $delay;
  transition-delay: $delay;
}

@mixin orx-transition-duration($duration) {
  -webkit-transition-duration: $duration;
  -moz-transition-duration: $duration;
  -ms-transition-duration: $duration;
  -o-transition-duration: $duration;
  transition-duration: $duration;
}