/**
 * CURSOR
 *
 * @type utilities
 */

/** ----------------------------------------------------------------
 * COMMON
 */

  .wait { cursor: wait !important; }
  .is-collapse { cursor: pointer; }