/**
 * HEADING
 *
 * @type specific
 */


/** ----------------------------------------------------------------
 * COMMON
 */

h1, h2, h3,
h4, h5, h6 { font-weight: normal; }

h3 { font-size: 16px; text-transform: none; font-weight: $orx-font-weight-bold; }

.h2-style { font-family: $orx-font-family-primary; margin: 0; padding: 0; font-size: 55px; }
