/**
 * RESET
 *
 * @type utilities
 */

/** ----------------------------------------------------------------
 * COMMON
 */

  .reset-button { background: transparent; margin: 0; border: 0 none; padding: 0; display: inline-block; cursor: pointer; }
  .reset-list { list-style: none; margin: 0; padding: 0; }
  .reset-white { font-size: 0; }
  .reset-address { font-style: normal; }
  .reset-input { @include orx-reset-input; }