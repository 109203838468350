/**
 * SLIDER FULL
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.slider-full {
  .slider-full-item {
    height: 213px; outline: 0 none; position: relative;

    img {
      @include orx-size(100%);
      object-fit: cover; outline: 0 none;
    }
    .h4-style {
      @include position(absolute, null null 15px 15px);
      background-color: rgba(black, 0.8); padding: 10px 15px; color: white;
    }
  }
}
/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .slider-full {
    .slider-full-item { height: 495px; }
  }
}
