/**
 * SIDEBAR RIGHT BLOCK
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.sidebar-right-block {
  position: relative; display: flex; flex-direction: column;

  .h2-style { font-weight: bold; }
  .sidebar-main { background-color: white; box-shadow: 0 2px 6px rgba(black, 0.12); border-radius: 6px; padding: 25px; margin-bottom: 30px; font-size: 16px; line-height: 24px; }
  .sidebar-right {
    background-color: $orx-brand-secondary; padding: 20px; margin: 0 -15px 20px; color: white; font-size: 16px; line-height: 24px;

    .h3-style { font-size: 24px; font-weight: 300; padding-bottom: 10px; margin-top: 5px; border-bottom: 1px solid rgba(white, 0.1); }
    .h4-style { font-size: 18px; font-weight: 700; margin-bottom: 0; }
    .txt { margin: 8px 0; font-weight: 300; color: white !important; }
    .image-wrapper {
      height: 180px; margin-bottom: 15px;

      &:last-child { margin-bottom: 0; }
      img {
        @include orx-size(100%);
        object-fit: cover;
      }
    }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .sidebar-right-block {
    flex-direction: row; margin: 105px 0 0;

    .sidebar-main { width: calc(100% - 400px); margin-bottom: 20px; margin-right: 20px; padding: 30px 40px; border-radius: 6px; box-shadow: 0 2px 6px rgba(black, 0.12); }
    .sidebar-right { width: 380px; border-radius: 6px; margin: 0; box-shadow: 0 2px 6px rgba(black, 0.12); }
  }
}