/**
 * HEADER
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.header {
  @include position(fixed, 0 null null 0);
  @include orx-size(100%,50px);
  color: white; display: flex; align-items: center; z-index: 100;
  background: -moz-linear-gradient(left, rgba(black, 0.6) 0%, rgba(black, 0.9) 100%);
  background: -webkit-linear-gradient(left, rgba(black, 0.6) 0%,rgba(black, 0.9) 100%);
  background: linear-gradient(to right, rgba(black, 0.6) 0%,rgba(black, 0.9) 100%);
  transition: all 0.2s ease-in-out;

  &.is-sticky {
    background: -moz-linear-gradient(left, rgba(black, 1) 0%, rgba(black, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(black, 1) 0%,rgba(black, 1) 100%);
    background: linear-gradient(to right, rgba(black,1) 0%,rgba(black, 1) 100%);
    height: 60px; box-shadow: 0 2px 6px rgba(black, 0.4);

    .logo-wrap {
      .link-style { transform: scale(0.85); }
    }
  }
  .container { position: relative; }
  .logo-wrap {
    @include position(absolute, 50% null null 15px);

    transform: translateY(-50%);

    .link-style {
      color: white; display: inline-flex; align-items: center; justify-content: center; text-decoration: none; outline: 0 none; transform: scale(1); transition: all 0.2s ease-in-out;

      .logo-label { font-size: 18px; font-family: $orx-font-family-secondary; text-transform: uppercase; transition: all 0.2s ease-in-out; }
      strong {
        font-family: $orx-font-family-primary; font-style: italic; text-transform: none;

        span { font-size: 23px; }
      }
    }
    img { width: 53px; transition: all 0.2s ease-in-out; }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .header {
    height: 80px;

    .logo-wrap {
      .link-style {
        .logo-label { font-size: 28px; }
        strong {
          span { font-size: 38px; }
        }
      }
      img { width: 110px; }
    }
  }
}