/**
 * COLOR
 *
 * @type specific
 */


/** ----------------------------------------------------------------
 * COMMON
 */

.primary { color: $orx-brand-primary; }
.secondary { color: $orx-brand-secondary; }
.unused { color: $orx-gray; }

.bg-primary { background-color: $orx-brand-primary; }
.bg-secondary { background-color: $orx-brand-secondary; }