/**
 * MIXIN BORDER
 *
 * @type mixin
 */


/** ----------------------------------------------------------------
 * COMMON
 */

  @mixin orx-border($color, $width: 1px, $style: solid) {
    border: $width $style $color;
  }