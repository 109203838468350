/**
 * MIXIN SIZE
 *
 * @type mixin
 */


/** ----------------------------------------------------------------
 * COMMON
 */

@mixin orx-size($width, $height: $width) {
  width: $width; height: $height;
}