/**
 * MAIN LAYOUT
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.main-layout {
  position: relative;

  .bg-cover {
    position: relative;

    &:before {
      @include position(absolute, 51px null null 0);
      @include orx-size(100%, calc(100% - 81px));
      content: "";
      background: -moz-linear-gradient(top, rgba(black, 0.6) 0%, rgba(black, 0) 100%);
      background: -webkit-linear-gradient(top, rgba(black, 0.6) 0%,rgba(black, 0) 100%);
      background: linear-gradient(to bottom, rgba(black, 0.6) 0%,rgba(black, 0) 100%);
    }
    &:after {
      @include position(absolute, null null 0 0);
      @include orx-size(100%, 30px);
      background: url("../../_core/css_imgs/fill/fill-bg.svg") no-repeat; background-size: cover;  content: "";
      transition: all 0.2s ease-in-out;
    }
    img { width: 100%; height: 250px; object-fit: cover; transition: all 0.2s ease-in-out; }
    .h2-style {
      @include position(absolute, 128px null null 50%);
      font-size: 16px; font-weight: 300; text-align: center; text-shadow: 0 3px 6px rgba(black, 0.5); padding: 0;
      transform: translate(-50%, -50%); width: 78%;

      strong { font-size: 20px; font-weight: 600; margin-top: 10px; display: block; }
      span {
        background-color: rgba(black, 0.6); color: $orx-brand-primary; font-size: 26px; font-weight: 700; margin: 6px -8px 0; padding: 6px 0; display: block; position: relative;

        &:before {
          @include position(absolute, 56% null null 20px);
          @include orx-size(30px, 2px);
          background-color: $orx-brand-primary; content: "";
          transform: translateY(-50%);
        }
        &:after {
          @include position(absolute, 56% 20px null null);
          @include orx-size(30px, 2px);
          background-color: $orx-brand-primary; content: "";
          transform: translateY(-50%);
        }
      }
    }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .main-layout {
    .bg-cover {
      &:after { height: 61px; }
      img { height: 300px; }
    }
  }
}

@media #{$orx-mobile-first-lg} {
  .main-layout {
    .bg-cover {
      &:before { top: 81px; }
      &:after { height: 98px; }
      img { height: 440px; }
      .h2-style {
        top: 50%; font-size: 28px; width: auto;

        strong { font-size: 30px; }
        span {
         font-size: 50px; margin: 6px -70px 0; padding: 11px 0;

          &:before { width: 60px; }
          &:after { width: 60px; }
        }
      }
    }
  }
}

@media #{$orx-mobile-first-xl} {
  .main-layout {
    .bg-cover {
      &:after { height: 115px; }
      img { height: 550px; }
    }
  }
}