/**
 * INPUT WRAP
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.input-wrap {
  margin-bottom: 10px; position: relative;

  label { font-size: 12px; font-weight: $orx-font-weight-bold; letter-spacing: 1px; line-height: 40px; text-transform: uppercase; }
  .input-style {
     @include orx-size(100%,40px);
    background-color: white; color: black; font-family: $orx-font-family-primary; font-size: 14px; font-weight: $orx-font-weight-regular; border: 1px solid $orx-border-light-color; border-radius: 4px; padding: 0 15px; display: flex; align-items: center; outline: none;
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .input-wrap { }
}