/**
 * CUSTOM INPUT
 *
 * @type element
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.elem-custom-input {
  @include orx-size(100%, 36px);
  background-color: rgba(black, 0.2); color: white; border: 1px solid rgba(white, 0.1); padding: 0 10px; -webkit-appearance: none;
}