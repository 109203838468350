/**
 * HEADER
 *
 * @type element
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.elem-icon {
  font-weight: normal !important; font-style: normal !important; font-family: $orx-font-family-icon !important; text-transform: none; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;

  &:before { content: attr(data-icon); }
}