/**
 * COLUMN WRAP
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.column-wrap {
  position: relative; display: flex; flex-wrap: wrap;

  .column-item {
    width: 100%; color: black; background-color: white; border-radius: 5px; box-shadow: 0 2px 6px rgba(black, 0.12); padding: 20px; margin: 0 0 30px; overflow: hidden;

    &:last-child { margin-bottom: 0; }
    .image-wrapper {
      height: 249px; margin: -20px -20px 0 -20px; position: relative; overflow: hidden;

      &:before {
        @include position(absolute, 0 null null 0);
        @include orx-size(100%);
        background-color: rgba(black, 0.4); content: ""; z-index: 2;
        transition: all 0.2s ease-in-out;
      }
      img {
        @include orx-size(100%);
        object-fit: cover;
        transition: all 0.2s ease-in-out;
      }
      .h3-style {
        @include position(absolute, 50% null null 50%);
        color: white; font-size: 26px; font-weight: 300; text-transform: uppercase; margin: 0; text-shadow: 0 2px 5px rgba(black, 0.4);
        transform: translate(-50%, -50%); z-index: 3; letter-spacing: 2px; white-space: nowrap;
      }
    }
    .txt { font-size: 16px; line-height: 24px; margin-top: 15px; min-height: inherit; }
    .button-wrap {
      text-align: right; margin-top: 10px;

      .btn-style { height: 41px; background-color: black; color: white; border-radius: 5px; display: inline-flex; align-items: center; justify-content: center; text-transform: uppercase; font-size: 16px; font-weight: 400; letter-spacing: 1px; padding: 0 20px; }
    }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .column-wrap {
    .column-item {
      .image-wrapper { height: 115px; }
      .button-wrap { text-align: center; }
    }
  }
}

@media #{$orx-mobile-first-lg} {
  .column-wrap {
    margin: 105px 0 0; flex-wrap: inherit;

    .column-item {
      width: calc(100% / 3); margin: 0 15px; transition: all 0.2s ease-in-out;

      .image-wrapper {
        margin: 0; height: 249px;

        .txt { min-height: 97px; }
      }
      &:first-child { margin-left: 0; }
      &:last-child { margin-right: 0; }
      &:hover {
        box-shadow: 0 2px 6px rgba(black, 0.25); cursor: pointer;

        .image-wrapper {
          margin: 0;

          &:before { background-color: rgba(black, 0.25); }
          img {
            @include orx-size(100%);
            object-fit: cover; transform: scale(1.05);
          }
          .h3-style {
            @include position(absolute, 50% null null 50%);
            color: white; font-size: 26px; font-weight: 300; text-transform: uppercase; margin: 0; text-shadow: 0 2px 5px rgba(black, 0.4);
            transform: translate(-50%, -50%); z-index: 3; letter-spacing: 2px; white-space: nowrap;
          }
        }
        .button-wrap {
          .btn-style { background-color: $orx-brand-primary;}
        }
      }
      .button-wrap {
        text-align: right;

        .btn-style { transition: all 0.2s ease-in-out; }
      }
    }
  }
}