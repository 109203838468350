/**
 * DEFAULT WRAP
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.default-wrap {
  color: black; top: -52px; position: relative;
  transition: all 0.2s ease-in-out;

  .container { }
  .block-full-white {
    background-color: white; border-radius: 6px; box-shadow: 0 2px 6px rgba(black, 0.12); padding: 30px 25px; margin-top: 5px;

    .presentation-page & {
      margin-top: 30px;
    }
    .h2-style { margin-bottom: 20px; font-weight: bold; }
    .h3-style { font-size: 20px; margin: 30px 0 10px; }
    .column-wrap-content { display: flex; flex-direction: column; }
    .txt { font-size: 16px; line-height: 24px; }
    .item-content { margin-bottom: 10px; }
  }
  .list-bullet {
    .txt {
      padding-left: 20px; margin-top: 8px; position: relative; font-size: 16px;

      &:before {
        @include position(absolute, -9px null null 5px);
        content: "."; font-size: 22px; font-weight: bold;
      }
    }
  }
  .block-full {
    margin: 40px 0 80px;

    &.bg-white { background-color: white; }
    .description-block {
      display: flex; flex-direction: column; margin: 0;

      .item {
        width: 100%; background-color: white; padding: 15px; margin: 0 0 30px; border-radius: 6px; box-shadow: 0 2px 6px rgba(black, 0.12);

        .title { font-size: 22px; font-weight: bold; margin-bottom: 15px; text-transform: uppercase; }
        .txt { font-size: 16px; margin-bottom: 10px; }
      }
    }
  }
  .h2-style { font-size: 24px; font-weight: $orx-font-weight-light; line-height: 28px; border-bottom: 1px solid $orx-border-light-color; padding-bottom: 15px; }
  .audit-style {
    text-align: center; padding: 15px 0; margin: 50px 0;

    .h3-style { font-size: 22px; }
    .txt { font-size: 16px; }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .default-wrap {
    top: -80px;

    .block-full-white {
      padding: 30px 40px;

      .column-wrap-content { display: flex; flex-direction: row; }
    }
    .h2-style { font-size: 28px; line-height: 42px; }
    .list-bullet { margin-bottom: 30px; }
  }
}

@media #{$orx-mobile-first-lg} {
  .default-wrap {
    top: -220px;

    .block-full-white { margin-top: 105px; }
    .block-full {
      .description-block {
        display: flex; flex-direction: row; margin: 0 -15px;

        .item {
          width: calc(100% / 3); padding: 20px; margin: 0 15px;

          .title { font-size: 22px; font-weight: bold; margin-bottom: 15px; text-transform: uppercase; }
          .txt { font-size: 16px; margin-bottom: 10px; }
        }
      }
    }
  }
}