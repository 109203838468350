/**
 * ADVERTISING
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.advertising {
  position: relative; margin-bottom: 50px;

  .home & { margin-bottom: 0; }
  .presentation-page & { margin-bottom: 0; }
  .bureau-page & { margin: 35px 0 0; }
  .image-wrapper {
    height: 250px; position: relative;

    > img {
      @include orx-size(100%);
      object-fit: cover; border-radius: 5px; box-shadow: 0 2px 6px rgba(black, 0.12);
    }
    &:hover {
      > img { box-shadow: 0 2px 6px rgba(black, 0.25); }
    }
    &:before {
      @include position(absolute, 0 null null 0);
      @include orx-size(100%);
      content: "";
      background: -moz-linear-gradient(-45deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.02) 1%, rgba(255,255,255,1) 64%, rgba(255,255,255,1) 100%);
      background: -webkit-linear-gradient(-45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0.02) 1%,rgba(255,255,255,1) 64%,rgba(255,255,255,1) 100%);
      background: linear-gradient(135deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0.02) 1%,rgba(255,255,255,1) 64%,rgba(255,255,255,1) 100%);
    }
    .txt-block {
      @include position(absolute, 30px 0 null null);
      text-align: center; width: 100%;

      .txt { font-size: 20px; font-weight: 600; color: black; }
      .btn-advertising {
        border-radius: 4px; background-color: black; color: white; text-transform: uppercase; letter-spacing: 1px; display: inline-flex; align-items: center; justify-content: center; padding: 0 25px; height: 41px;
        margin-top: 15px;
      }
    }
    .logo-wrap {
      margin: 61px 0 0; display: flex; align-items: center; justify-content: center;

      .logo-label { color: black; font-size: 30px; font-family: $orx-font-family-secondary; text-transform: uppercase; transition: all 0.2s ease-in-out; }
      strong {
        font-family: $orx-font-family-primary; font-style: italic; text-transform: none;

        span { font-family: "PT Serif", arial, sans-serif; font-size: 37px; }
      }
      img { width: 94px; }
    }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .advertising {
    .home & { margin-bottom: -130px; }
    .presentation-page & { margin-bottom: -130px; }
    .bureau-page & { margin: 80px 0 -130px; }
    &:hover {
      .image-wrapper {
        .txt-block {
          .btn-advertising { background-color: $orx-brand-primary; }
        }
      }
    }
    .image-wrapper {
      .txt-block {
        @include position(absolute, 35px 115px null null);
        text-align: center; width: auto;

        .txt { font-size: 22px; font-weight: 300; }
        .btn-advertising { transition: all 0.2s ease-in-out; }
      }
      .logo-wrap { margin: 15px 0; }
    }
  }
}