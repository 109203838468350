/**
 * BOOTSTRAP
 *
 * @type specific
 */

@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../node_modules/bootstrap/scss/bootstrap-grid";

// UTILITIES
@import "../../../../node_modules/bootstrap/scss/utilities/clearfix";
@import "../../../../node_modules/bootstrap/scss/utilities/position";
@import "../../../../node_modules/bootstrap/scss/utilities/spacing";
@import "../../../../node_modules/bootstrap/scss/utilities/text";
@import "../../../../node_modules/bootstrap/scss/utilities/visibility";