/**
 * TRANSFORM
 *
 * @type mixin
 */


/** ----------------------------------------------------------------
 * COMMON
 */

@mixin orx-transform($origin) {
  -webkit-transform: $origin;
  -moz-transform: $origin;
  -ms-transform: $origin;
  -o-transform: $origin;
  transform: $origin;
}

@mixin orx-transform-origin($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -o-transform-origin: $origin;
  transform-origin: $origin;
}

@mixin orx-transform-rotate($rotate) {
  -webkit-transform: rotate($rotate);
  -moz-transform: rotate($rotate);
  -ms-transform: rotate($rotate);
  -o-transform: rotate($rotate);
  transform: rotate($rotate);
}

@mixin orx-transform-scale($x, $y: $x) {
  -webkit-transform: scale($x, $y);
  -moz-transform: scale($x, $y);
  -ms-transform: scale($x, $y);
  -o-transform: scale($x, $y);
  transform: scale($x, $y);
}

@mixin orx-transform-scaleX($x) {
  -webkit-transform: scaleX($x);
  -moz-transform: scaleX($x);
  -ms-transform: scaleX($x);
  -o-transform: scaleX($x);
  transform: scaleX($x);
}

@mixin orx-transform-scaleY($y) {
  -webkit-transform: scaleY($y);
  -moz-transform: scaleY($y);
  -ms-transform: scaleY($y);
  -o-transform: scaleY($y);
  transform: scaleY($y);
}

@mixin orx-transform-translate($x, $y: $x) {
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin orx-transform-translateX($x) {
  -webkit-transform: translateX($x);
  -moz-transform: translateX($x);
  -ms-transform: translateX($x);
  -o-transform: translateX($x);
  transform: translateX($x);
}

@mixin orx-transform-translateY($y) {
  -webkit-transform: translateY($y);
  -moz-transform: translateY($y);
  -ms-transform: translateY($y);
  -o-transform: translateY($y);
  transform: translateY($y);
}