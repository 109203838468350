/**
 * ACCOUNT BLOCK
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.account-block {
  width: 100%; position: relative;

  .h3-style { font-size: 26px; font-weight: $orx-font-weight-bold; letter-spacing: 2px; line-height: 40px; }
  .button-wrap-content {
    margin: 20px 0; display: flex; align-items: center;

    .button-wrap { margin-right: 15px; }
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .account-block {
    width: 40%;

    .button-wrap-content {
      flex-direction: column; align-items: flex-start;

      .button-wrap { }
    }
  }
}

@media #{$orx-mobile-first-xl} {
  .account-block {
    .button-wrap-content { flex-direction: row; align-items: center; }
  }
}