/**
 * BUTTONS
 *
 * @type element
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.elem-button {
  @include orx-size(auto, 43px);
  background-color: $orx-brand-secondary; color: white; letter-spacing: 2px; font-size: 16px; font-weight: $orx-font-weight-semi-bold; border: 0 none; border-radius: 6px; outline: none; padding: 0 40px; display: flex; align-items: center; justify-content: center;
  box-shadow: 0 1px 1px rgba(black, 0.3), 0 0 0 1px rgba(white, 0.15) inset;
  transition: all 0.2s ease-in-out;
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .elem-button {
    padding: 0 80px; cursor: pointer;

    &:hover { background-color: $orx-brand-primary; }
  }
}