/**
 * FONTS
 *
 * @type specific
 */


/** ----------------------------------------------------------------
 * COMMON
 */

@font-face {
  font-family: "baran-icons";
  src:
    url("/src/_core/fonts/baran-icons.svg") format("svg"),
    url("/src/_core/fonts/baran-icons.ttf") format("ttf"),
    url("/src/_core/fonts/baran-icons.woff") format("woff");
}