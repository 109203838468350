/**
 * COLUMN WRAP
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.component-full {
  position: relative;

  .image-wrapper {
    height: 400px; position: relative;

    img {
      @include orx-size(100%);
      object-fit: cover;
    }
    &:before {
      @include position(absolute, 0 null null 0);
      @include orx-size(100%, 400px);
      content: "";
      background: -moz-linear-gradient(-45deg, rgba(28,24,23,1) 36%, rgba(28,24,23,0.94) 40%, rgba(255,255,255,0.02) 99%, rgba(255,255,255,0) 100%);
      background: -webkit-linear-gradient(-45deg, rgba(28,24,23,1) 36%,rgba(28,24,23,0.94) 40%,rgba(255,255,255,0.02) 99%,rgba(255,255,255,0) 100%);
      background: linear-gradient(135deg, rgba(28,24,23,1) 36%,rgba(28,24,23,0.94) 40%,rgba(255,255,255,0.02) 99%,rgba(255,255,255,0) 100%);
    }
  }
  h3 { letter-spacing: 2px; font-size: 26px; font-weight: 300; text-transform: uppercase; line-height: 24px; margin-top: 47px; text-shadow: 0 2px 6px rgba(black, 0.25); }
  .block-txt {
    @include position(absolute, 25px null null 0);
    @include orx-size(100%, auto);
    color: white; text-align: center; padding: 0 20px; font-size: 16px; line-height: 24px;
  }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .component-full {
    h3 { margin-top: 110px; }
  }
}