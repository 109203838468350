/**
 * VARIABLES
 *
 * @type specific
 */

/** ----------------------------------------------------------------
 * COMMON
 */

/** ----------------------------------------------------------------
 * COLOR
 */

// BRAND
$orx-brand-primary: #ada063 !default;
$orx-brand-primary-darker: red !default;
$orx-brand-secondary: #18120c !default;
$orx-brand-secondary-light: #00aeff !default;
$orx-brand-tertiary: #0e86ca !default;
$orx-brand-quaternary: #222e3c !default;

// DEFAULT BASE GRAY
$orx-gray: #5f5f5f !default;
$orx-gray-8: #888 !default;
$orx-gray-3: #333 !default;

// SPECIFIC GRAY
$orx-gray-c4: #c4c4c4 !default;
$orx-gray-fa: #fafafa !default;
$orx-gray-f1: #f1f1f1 !default;
$orx-gray-ea: #eaeaea !default;
$orx-gray-ed: #ededed !default;
$orx-gray-e4: #e4e4e4 !default;
$orx-gray-e2: #e2e2e2 !default;
$orx-gray-3c: #3c3c3c !default;
$orx-gray-70: #707070 !default;

// FONT COLOR
$orx-brand-font-color-primary: black !default;
$orx-brand-font-color-secondary: #676767 !default;

// MESSAGE
$orx-message-info: #01a4e6 !default;
$orx-message-success: #18a701 !default;
$orx-message-warning: #ffce4c !default;
$orx-message-error: #e00042 !default;
$orx-message-promo: #b60036 !default;

// BORDER
$orx-border-default-color: #707070 !default;
$orx-border-primary-color: $orx-brand-primary !default;
$orx-border-light-color: #e5e5e5 !default;

// WRAPPER
$orx-wrapper-full-gray: #f9f8f8 !default;

/** ----------------------------------------------------------------
 * TYPE
 */

// FAMILY
$orx-font-family-base: arial, sans-serif !default;
// custom primary
$orx-font-family-primary: Poppins, $orx-font-family-base !default;
$orx-font-family-secondary: "PT Serif", $orx-font-family-base !default;
// icon
$orx-font-family-icon: "baran-icons" !default;

// FONT WEIGHT
$orx-font-weight-light: 300 !default;
$orx-font-weight-regular: 400 !default;
$orx-font-weight-medium: 500 !default;
$orx-font-weight-semi-bold: 600 !default;
$orx-font-weight-bold: 700 !default;
$orx-font-weight-black: 800 !default;

/** ----------------------------------------------------------------
 * SPACING
 */

// GUTTER
$orx-gutter-base: 30px !default;
$orx-gutter-half-width: 30px / 2 !default;

// TEXT SHADOW
$orx-text-shadow: 0 2px 12px rgba(black, 0.12);

// BOX SHADOW
$orx-box-shadow: 0 2px 12px rgba(black, 0.12);

/** ----------------------------------------------------------------
 * LAYOUT
 */

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px
);

// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 991px,
  xl: 1400px
);

// MEDIA QUERY
$orx-mobile-first-sm: "only screen and (min-width: #{map-get($grid-breakpoints, "sm")})" !default;  // min 576px
$orx-mobile-first-md: "only screen and (min-width: #{map-get($grid-breakpoints, "md")})" !default;  // min 768px
$orx-mobile-first-lg: "only screen and (min-width: #{map-get($grid-breakpoints, "lg")})" !default;  // min 991px
$orx-mobile-first-xl: "only screen and (min-width: #{map-get($grid-breakpoints, "xl")})" !default;  // min 1370px

@media #{$orx-mobile-first-xl} {
  .wrap-component {
    // CONTAINER FLUID
    .inner-wrap-component { max-width: #{map-get($container-max-widths, "xl")}; }
  }
}
