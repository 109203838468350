/**
 * ACCOUNT CREATE
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.account-create {
  width: 100%; padding-left: 0; position: relative;

  .h3-style { font-size: 26px; font-weight: $orx-font-weight-bold; letter-spacing: 2px; line-height: 40px; }
  .inner-block { background-color: white; border-radius: 6px; box-shadow: 0 2px 6px rgba(black, 0.12); padding: 30px 40px; }
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-md} {
  .account-create { width: 60%; padding-left: 50px; }
}