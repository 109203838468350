/**
 * SLIDER PRODUCT
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.slider-product {
  position: relative; padding: 0 40px; margin: 25px 0 80px;

  .slider-product-content { margin: 0 -15px; }
  .slider-product-item {
    outline: 0 none; margin: 0 15px;

    .image-wrapper {
      outline: 0 none; height: 200px;

      img {
        @include orx-size(100%);
        outline: 0 none; object-fit: contain;
      }
    }
  }
}