/**
 * LINK STYLE
 *
 * @type element
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.link-style {
  color: black; font-size: 16px; text-decoration: underline;
}

/** ----------------------------------------------------------------
 * MEDIA QUERY
 */

@media #{$orx-mobile-first-lg} {
  .link-style {
    &:hover { color: $orx-brand-primary; }
  }
}