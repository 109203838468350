/**
 * BLOCK EDITO
 *
 * @type component
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.block-edito {
  position: relative; margin-bottom: 50px;

  .h2-style { margin: 15px 0; font-weight: bold; letter-spacing: 1px; }
  .txt {
    font-size: 22px; line-height: 32px; text-align: center;

    strong { color: $orx-brand-secondary; display: block; }
  }
  .link-style { color: black; text-decoration: underline; margin-top: 5px; font-size: 16px; }
}