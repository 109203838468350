/**
 * OVERLAY FULL
 *
 * @type element
 */

/** ----------------------------------------------------------------
 * COMMON
 */

.elem-overlay-full {
  @include position(fixed, 0 null null 0);
  @include orx-size(100%);
  background-color: rgba(black, 0.2); z-index: 1;
}